<template>
  <div class="privacy">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <main>
        <img src="~img/ecology/13.png" class="logo" />
        <h4>安谋科技隐私政策</h4>
        <div class="heng"></div>
        <ul>
          <li class="left_br">
            <p>
              <span>最后更新日期：{{ date }}</span>
            </p>
          </li>
          <!-- <li>
            <p><span>安谋科技（中国）有限公司及其子公司和分支机构</span>（简称<span>“安谋科技”、“公司”</span>或者<span>“我们”</span>）尊重您的隐私权利并重视保护您的个人信息。本《安谋科技隐私政策》（简称<span>“隐私政策”</span>）旨在向您介绍我们如何收集和处理您的个人信息，以及您可以如何管理您的个人信息。<span>请您在向我们提交个人信息之前仔细阅读本隐私政策，尤其是粗体显示的内容，以确保充分了解情况.</span></p>
          </li>
          <li>
            <p>
              本隐私政策依据中华人民共和国<span>（“中国”）</span>数据保护和个人信息保护等相关法律法规制定。本隐私政策中所指的“个人信息”是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，包括姓名、通讯地址、账号信息、联系方式等，但不包括匿名化处理后的信息。其中，“个人信息”中的“敏感个人信息”是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括证件号码、财产状况、行踪轨迹、14岁以下（含）儿童信息等。
            </p>
          </li>
          <li>
            <p>如您对本隐私政策有任何问题或疑虑，请随时通过本隐私政策第九条所提供的联系方式与我们联系。</p>
          </li>
          <li>
            <p>
              本隐私政策将帮助您了解以下内容:<br />
              1. &nbsp;我们收集的个人信息的种类以及目的<br />
              2. &nbsp;Cookies及相关技术告知<br />
              3. &nbsp;我们如何委托处理、向第三方提供或公开披露您的个人信息<br />
              4. &nbsp;我们如何保护您的个人信息<br />
              5. &nbsp;我们如何存储您的个人信息<br />
              6. &nbsp;您的个人信息主体权利<br />
              7. &nbsp;我们如何处理未成年人的个人信息<br />
              8. &nbsp;本隐私政策的更新<br />
              9. &nbsp;如何联系我们<br />
            </p>
          </li>
          <li>
            <p><span>一、 我们收集的个人信息的种类以及目的</span></p>
          </li>
          <li>
            <p>
              •&nbsp; 当您申请和我们进行商务合作时，我们需要收集您的姓名、职位、电子邮箱、联系电话、地址、公司名称、公司网站、业务类型、业务方向等商业合作的细节内容以了解您的具体需求，与您联络开展商业合作。我们还可能将上述信息用于a) 处理和回复您或您所在公司的问题和意见；b) 提供您或您所在公司可能感兴趣的产品和服务的信息; c) 征求您或您所在公司的信息和反馈，包括通过调研和问卷调查等方式。<br />
              •&nbsp; 当您选择向我们投递简历以应聘我们的岗位时，我们会收集您的<span>身份信息及简历信息</span> ，例如姓名、出生日期、性别及籍贯、照片、您的学历、工作经历、实习经历、项目经验、联系方式、国家/地区、语言能力、求职意向、自我描述、获奖经历、推荐人信息、亲属信息、兼职信息等，以初步了解您的情况。<br />
              •&nbsp; 如您到访我们的办公室，您须登记为访客，并提供您的姓名、电子邮箱、电话号码、公司名称及抵达时间和日期。<br />
              •&nbsp; 我们可能会通过您提供的<u>手机号码、邮箱</u>向您发送我们的公司信息、产品信息、服务信息以及有关活动等信息（统称<span>“营销信息”</span>），以便您更好的使用我们公司的产品和服务等。
            </p>
          </li>
          <li>
            <p><span>二、 Cookies及相关技术告知</span></p>
          </li>
          <li>
            <p>当您通过计算机或移动设备访问公司的官方网站，我们将自动收集您的a) 技术信息，例如互联网协议（IP）地址、浏览器类型和版本、时区设置和位置、浏览器插件类型和版本、操作系统和平台以及您在设备上使用的用于访问官方网站的其他技术；b) 使用信息，例如有关您如何使用我们的网站、产品或服务的信息。</p>
          </li>
          <li>
            <p>
              收集该等信息使我们可以更好地了解我们网站的访问者，比如访问者来自哪里以及我们网站有哪些吸引访问者的内容。我们将该等信息用于内部分析以及为我们网站访问者提升我们网站的质量和相关性的目的。我们同时也需要留存相应的日志来记录我们网站的运行状态以符合中国法律法规的规定。
            </p>
          </li>
          <li>
            <p>
              这些信息一般通过我们使用的Cookies及相关技术来收集的。Cookies是您在浏览网站时存储在您的计算机或移动设备上的数据文件，通常包含标识符、站点名称以及一些号码和字符。我们使用Cookies对网站进行技术管理，确保您正常访问我们的网站以及进行流量监控。我们所使用的Cookies以匿名方式提供信息，并且不允许我们收集可用来对人员进行识别的数据。您可以通过修改浏览器的设置，以使用或者禁用Cookies。当您禁用Cookies时，不会影响您使用网站的基本功能。
            </p>
          </li>
          <li>
            <p>若要详细了解Cookies如何工作，您可以访问http://www.allaboutcookies.org/。</p>
          </li>
          <li>
            <p><span>三、 我们如何委托处理、向第三方提供或公开披露您的个人信息</span></p>
          </li>
          <li>
            <p>（一） 委托处理</p>
          </li>
          <li>
            <p>为了提升信息处理效率，我们可能会委托第三方代表我们处理您的个人信息。对我们委托处理个人信息的第三方，我们会与其签署严格的保密协定，要求其按照我们的要求、本隐私政策以及法律法规要求的保密和安全措施来处理个人信息。</p>
          </li>
          <li>
            <p>（二） 向第三方提供</p>
          </li>
          <li>
            <p>
              在如下情形下，我们可能会将您的个人信息提供给第三方<br />
              •&nbsp; 向我们的<span>关联方、第三方服务商以及合作伙伴</span>提供您的个人信息，以便其协助我们实现本隐私政策所述的处理目的。<br />
              •&nbsp; 向相关的<span>执法机构、监管机构、政府、法院或者其他第三方</span>进行披露，并且该等披露是我们认为必要的并且是为了（1）符合某一项适用法律法规；（2）行使、建立或者抗辩我们的合法权利，或者（3）保护您或者任何其他人的重要合法权益。<br />
              •&nbsp;
              <span>我们发生变更或者涉及合并、收购、重组、清算等程序</span>时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续依据本隐私政策所披露的内容处理您的个人信息。如新的持有您个人信息的公司、组织需要将您的个人信息用于本隐私政策未载明的目的，新的公司或组织将会依照可适用的法律法规的规定取得您的同意。<br />
              •&nbsp; 经过您的单独同意后，向<span>其他第三方</span>提供用于我们在征得您的单独同意时向您告知的目的。
            </p>
          </li>
          <li>
            <p>（三） 公开披露</p>
          </li>
          <li>
            <p>
              我们仅会在以下情况下，公开披露您的个人信息:<br />
              •&nbsp; 我们在获取您的单独同意后，公开披露您的个人信息；<br />
              •&nbsp; 在法律、法规、司法、法律程序、政府主管部门强制性的行政执法要求必须提供您的个人信息的情况下，我们可能会依据必须提供的范围和方式公开披露您的个人信息。在这种情况下，我们会请求提出要求的相关部门出具合理的文件，以确保该等要求具备合法的依据，并且相关部门具有合法权力和合理目的来获取您的个人信息。
            </p>
          </li>
          <li>
            <p>（四） 征得同意的例外</p>
          </li>
          <li>
            <p>
              <span
                >未经您本人同意，我们不会向除上述相关方以外的任何第三方提供或公开披露您的个人信息，下列情形除外：<br />
                •&nbsp; 为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br />
                •&nbsp; 为履行法定职责或者法定义务所必需；<br />
                •&nbsp; 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
                •&nbsp; 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br />
                •&nbsp; 依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；以及<br />
                •&nbsp; 法律法规规定的其他情形。</span
              >
            </p>
          </li>
          <li>
            <p>
              根据适用的法律法规，若公司对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或公司可能会对收集的信息进行匿名化地研究、统计分析和预测，用于为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向个人信息主体通知并征得其同意。
            </p>
          </li>
          <li>
            <p><span>四、 我们如何保护您的个人信息</span></p>
          </li>
          <li>
            <p>我们会采取合适的技术手段以及管理手段来保护我们收集和处理的您的个人信息。这些手段将根据您的个人信息所面临风险的高低来适用。</p>
          </li>
          <li>
            <p><span>请您知悉并理解，互联网并非绝对安全的环境。如您发现自己的个人信息发生泄漏的，请您立即通过本隐私政策第九条所提供的联系方式联系我们，以便我们采取相应措施。</span></p>
          </li>
          <li>
            <p>
              <span>如出现意外事件、不可抗力等情形导致您的个人信息出现泄漏时，我们将极力控制局面，及时告知您事件起因、我们采取的安全措施、您可以主动采取的安全措施等相关信息。如发生个人信息有关的安全事件，我们将根据相关法律法规的要求向有关主管机关报备，及时进行问题排查，并采取应急措施。</span>
            </p>
          </li>
          <li>
            <p><span>五、 我们如何存储您的个人信息</span></p>
          </li>
          <li>
            <p>
              <span>存储位置：</span>我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：<br />
              1、法律法规有明确规定；<br />
              2、获得您的单独同意。
            </p>
          </li>
          <li>
            <p>针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。</p>
          </li>
          <li>
            <p>
              <span>存储期限：</span>除法律法规另有规定外，我们将按如下期间存储您的信息：<br />
              •&nbsp; 我们会储存我们收集到的您的个人信息以满足我们的正当业务需求（例如向您提供服务或者来满足相应的法律、税务以及财务的要求）。<br />
              •&nbsp; 当我们没有这些需要使用到您的个人信息的正当业务需求时或者是中国法律法规规定的留存时间届满时，我们会删除您的个人信息或者对其进行匿名化处理，或者在匿名化或者删除皆不可能实现（例如您的个人信息已经在备份中存储）的时候，安全地储存您的这些个人信息并且将这些个人信息与其他的数据处理区隔开来。
            </p>
          </li>
          <li>
            <p><span>六、 您的个人信息主体权利</span></p>
          </li>
          <li>
            <p>
              您有如下个人信息主体权利：<br />
              •&nbsp; 如您希望<span>访问、更正、更新以及删除</span>您的个人信息，以及行使其他中国法下的法定个人信息主体权利（比如撤回允许我们收集和处理您个人信息的同意、更改您同意的范围、转移您的个人信息（如适用）），您可以通过本隐私政策第九条所提供的联系方式随时与我们取得联系。
              <br />
              •&nbsp; 您有权随时退订我们不时向您发送的营销信息。您可以通过点击每则营销信息中所列的“退订”链接进行退订。如您希望退订其他形式的营销信息（比如通过邮寄或者电话等方式进行的营销），您可以通过本隐私政策第九条所提供的联系方式随时与我们取得联系。
            </p>
          </li>
          <li>
            <p>
              我们将会按照适用的数据保护法律的要求以及时间回应所有我们从个人信息主体收到的行使个人信息主体权利的请求。为保障安全，我们可能需要您提供书面请求或以其他方式证明您的身份。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。
            </p>
          </li>
          <li>
            <p>
              <span
                >根据法律法规规定，我们在如下的情况下可能无法回应您的行权请求：<br />
                •&nbsp; 如果您的请求与我们履行法律法规规定的义务所相悖；<br />
                •&nbsp; 如果请求的信息与国家安全、国防安全直接相关；<br />
                •&nbsp; 如果请求的信息与公共安全、公共卫生、重大公共利益直接相关；<br />
                •&nbsp; 如果请求的信息与刑事侦查、起诉、审判和执行判决等直接相关；<br />
                •&nbsp; 如果我们有充分证据表明您存在主观恶意或滥用权利的；<br />
                •&nbsp; 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意；<br />
                •&nbsp; 如果回应您的行权请求将会导致您或者其他个人、组织的合法利益受到严重损害；<br />
                •&nbsp; 如果请求的信息涉及商业秘密。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</span>
            </p>
          </li>
          <li>
            <p><span>七、 我们如何处理未成年人的个人信息</span></p>
          </li>
          <li>
            <p>
              我们的网站、产品和服务主要面向成年人。如果没有父母或监护人的同意，不满14周岁的未成年人不得使用我们的网站、产品或服务。<span>如您是未满14岁的未成年人，您需要在您的父母或者法定监护人的指导下阅读本隐私政策。</span>我们只会在法律法规允许、父母或监护人明确同意的情况下收集未成年人的个人信息。如果我们发现自己在未事先获得父母或监护人明确同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            </p>
          </li>
          <li>
            <p>如果您是未成年人的父母或者法定监护人，当您对未成年人的个人信息有相关疑问时，可通过本隐私政策第九条所提供的联系方式与我们取得联系。</p>
          </li>
          <li>
            <p><span>八、 本隐私政策的更新</span></p>
          </li>
          <li>
            <p>
              本隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在专门页面上发布对隐私政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知。 本隐私政策所指的重大变更包括但不限于：<br />
              •&nbsp; 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />
              •&nbsp; 我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；<br />
              •&nbsp; 个人信息提供或公开披露的主要对象发生变化；<br />
              •&nbsp; 您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />
              •&nbsp; 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；<br />
              •&nbsp; 因个人信息保护影响评估表明存在高风险而需要更改个人信息处理行为。
            </p>
          </li>
          <li>
            <p>您可以通过本隐私政策首部的“最后更新日期”了解到本隐私政策的更新时间。<span>若您在本隐私政策变更和修订之后继续使用我们的网站、产品或服务，表示您同意接受更新后的隐私政策。</span></p>
          </li>
          <li>
            <p><span>九、 如何联系我们</span></p>
          </li>
          <li>
            <p>
              如您对我们如何使用您的个人信息或本隐私政策有任何的问题或者疑虑，特别是认为我们的个人信息处理行为损害了您的合法权益，您可通过如下联系方式联系我们。我们将尽快审核所涉问题，并按照法律法规的要求和期限及时予以回复。<br />
              •&nbsp; 公司：安谋科技（中国）有限公司<br />
              •&nbsp; 联系地址：上海市闵行区田林路1016号科技绿洲三期11号楼<br />
              •&nbsp; 联系方式：privacy@armchina.com
            </p>
          </li> -->
        </ul>
        <div class="content" v-html="content"></div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <main>
        <img src="~img/ecology/80.png" class="logo" />
        <h4>
          安谋科技隐私政策
        </h4>
        <div class="heng"></div>
        <ul>
          <li class="left_br">
            <p>
              <span>最后更新日期：{{ date }}</span>
            </p>
          </li>
          <!-- <li>
            <p><span>安谋科技（中国）有限公司及其子公司和分支机构</span>（简称<span>“安谋科技”、“公司”</span>或者<span>“我们”</span>）尊重您的隐私权利并重视保护您的个人信息。本《安谋科技隐私政策》（简称<span>“隐私政策”</span>）旨在向您介绍我们如何收集和处理您的个人信息，以及您可以如何管理您的个人信息。<span>请您在向我们提交个人信息之前仔细阅读本隐私政策，尤其是粗体显示的内容，以确保充分了解情况.</span></p>
          </li>
          <li>
            <p>
              本隐私政策依据中华人民共和国<span>（“中国”）</span>数据保护和个人信息保护等相关法律法规制定。本隐私政策中所指的“个人信息”是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，包括姓名、通讯地址、账号信息、联系方式等，但不包括匿名化处理后的信息。其中，“个人信息”中的“敏感个人信息”是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括证件号码、财产状况、行踪轨迹、14岁以下（含）儿童信息等。
            </p>
          </li>
          <li>
            <p>如您对本隐私政策有任何问题或疑虑，请随时通过本隐私政策第九条所提供的联系方式与我们联系。</p>
          </li>
          <li>
            <p>
              本隐私政策将帮助您了解以下内容:<br />
              1. &nbsp;我们收集的个人信息的种类以及目的<br />
              2. &nbsp;Cookies及相关技术告知<br />
              3. &nbsp;我们如何委托处理、向第三方提供或公开披露您的个人信息<br />
              4. &nbsp;我们如何保护您的个人信息<br />
              5. &nbsp;我们如何存储您的个人信息<br />
              6. &nbsp;您的个人信息主体权利<br />
              7. &nbsp;我们如何处理未成年人的个人信息<br />
              8. &nbsp;本隐私政策的更新<br />
              9. &nbsp;如何联系我们<br />
            </p>
          </li>
          <li>
            <p><span>一、 我们收集的个人信息的种类以及目的</span></p>
          </li>
          <li>
            <p>
              •&nbsp; 当您申请和我们进行商务合作时，我们需要收集您的姓名、职位、电子邮箱、联系电话、地址、公司名称、公司网站、业务类型、业务方向等商业合作的细节内容以了解您的具体需求，与您联络开展商业合作。我们还可能将上述信息用于a) 处理和回复您或您所在公司的问题和意见；b) 提供您或您所在公司可能感兴趣的产品和服务的信息; c) 征求您或您所在公司的信息和反馈，包括通过调研和问卷调查等方式。<br />
              •&nbsp; 当您选择向我们投递简历以应聘我们的岗位时，我们会收集您的<span>身份信息及简历信息</span> ，例如姓名、出生日期、性别及籍贯、照片、您的学历、工作经历、实习经历、项目经验、联系方式、国家/地区、语言能力、求职意向、自我描述、获奖经历、推荐人信息、亲属信息、兼职信息等，以初步了解您的情况。<br />
              •&nbsp; 如您到访我们的办公室，您须登记为访客，并提供您的姓名、电子邮箱、电话号码、公司名称及抵达时间和日期。<br />
              •&nbsp; 我们可能会通过您提供的<u>手机号码、邮箱</u>向您发送我们的公司信息、产品信息、服务信息以及有关活动等信息（统称<span>“营销信息”</span>），以便您更好的使用我们公司的产品和服务等。
            </p>
          </li>
          <li>
            <p><span>二、 Cookies及相关技术告知</span></p>
          </li>
          <li>
            <p>当您通过计算机或移动设备访问公司的官方网站，我们将自动收集您的a) 技术信息，例如互联网协议（IP）地址、浏览器类型和版本、时区设置和位置、浏览器插件类型和版本、操作系统和平台以及您在设备上使用的用于访问官方网站的其他技术；b) 使用信息，例如有关您如何使用我们的网站、产品或服务的信息。</p>
          </li>
          <li>
            <p>
              收集该等信息使我们可以更好地了解我们网站的访问者，比如访问者来自哪里以及我们网站有哪些吸引访问者的内容。我们将该等信息用于内部分析以及为我们网站访问者提升我们网站的质量和相关性的目的。我们同时也需要留存相应的日志来记录我们网站的运行状态以符合中国法律法规的规定。
            </p>
          </li>
          <li>
            <p>
              这些信息一般通过我们使用的Cookies及相关技术来收集的。Cookies是您在浏览网站时存储在您的计算机或移动设备上的数据文件，通常包含标识符、站点名称以及一些号码和字符。我们使用Cookies对网站进行技术管理，确保您正常访问我们的网站以及进行流量监控。我们所使用的Cookies以匿名方式提供信息，并且不允许我们收集可用来对人员进行识别的数据。您可以通过修改浏览器的设置，以使用或者禁用Cookies。当您禁用Cookies时，不会影响您使用网站的基本功能。
            </p>
          </li>
          <li>
            <p>若要详细了解Cookies如何工作，您可以访问http://www.allaboutcookies.org/。</p>
          </li>
          <li>
            <p><span>三、 我们如何委托处理、向第三方提供或公开披露您的个人信息</span></p>
          </li>
          <li>
            <p>（一） 委托处理</p>
          </li>
          <li>
            <p>为了提升信息处理效率，我们可能会委托第三方代表我们处理您的个人信息。对我们委托处理个人信息的第三方，我们会与其签署严格的保密协定，要求其按照我们的要求、本隐私政策以及法律法规要求的保密和安全措施来处理个人信息。</p>
          </li>
          <li>
            <p>（二） 向第三方提供</p>
          </li>
          <li>
            <p>
              在如下情形下，我们可能会将您的个人信息提供给第三方<br />
              •&nbsp; 向我们的<span>关联方、第三方服务商以及合作伙伴</span>提供您的个人信息，以便其协助我们实现本隐私政策所述的处理目的。<br />
              •&nbsp; 向相关的<span>执法机构、监管机构、政府、法院或者其他第三方</span>进行披露，并且该等披露是我们认为必要的并且是为了（1）符合某一项适用法律法规；（2）行使、建立或者抗辩我们的合法权利，或者（3）保护您或者任何其他人的重要合法权益。<br />
              •&nbsp;
              <span>我们发生变更或者涉及合并、收购、重组、清算等程序</span>时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续依据本隐私政策所披露的内容处理您的个人信息。如新的持有您个人信息的公司、组织需要将您的个人信息用于本隐私政策未载明的目的，新的公司或组织将会依照可适用的法律法规的规定取得您的同意。<br />
              •&nbsp; 经过您的单独同意后，向<span>其他第三方</span>提供用于我们在征得您的单独同意时向您告知的目的。
            </p>
          </li>
          <li>
            <p>（三） 公开披露</p>
          </li>
          <li>
            <p>
              我们仅会在以下情况下，公开披露您的个人信息:<br />
              •&nbsp; 我们在获取您的单独同意后，公开披露您的个人信息；<br />
              •&nbsp; 在法律、法规、司法、法律程序、政府主管部门强制性的行政执法要求必须提供您的个人信息的情况下，我们可能会依据必须提供的范围和方式公开披露您的个人信息。在这种情况下，我们会请求提出要求的相关部门出具合理的文件，以确保该等要求具备合法的依据，并且相关部门具有合法权力和合理目的来获取您的个人信息。
            </p>
          </li>
          <li>
            <p>（四） 征得同意的例外</p>
          </li>
          <li>
            <p>
              <span
                >未经您本人同意，我们不会向除上述相关方以外的任何第三方提供或公开披露您的个人信息，下列情形除外：<br />
                •&nbsp; 为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；<br />
                •&nbsp; 为履行法定职责或者法定义务所必需；<br />
                •&nbsp; 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
                •&nbsp; 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br />
                •&nbsp; 依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；以及<br />
                •&nbsp; 法律法规规定的其他情形。</span
              >
            </p>
          </li>
          <li>
            <p>
              根据适用的法律法规，若公司对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或公司可能会对收集的信息进行匿名化地研究、统计分析和预测，用于为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向个人信息主体通知并征得其同意。
            </p>
          </li>
          <li>
            <p><span>四、 我们如何保护您的个人信息</span></p>
          </li>
          <li>
            <p>我们会采取合适的技术手段以及管理手段来保护我们收集和处理的您的个人信息。这些手段将根据您的个人信息所面临风险的高低来适用。</p>
          </li>
          <li>
            <p><span>请您知悉并理解，互联网并非绝对安全的环境。如您发现自己的个人信息发生泄漏的，请您立即通过本隐私政策第九条所提供的联系方式联系我们，以便我们采取相应措施。</span></p>
          </li>
          <li>
            <p>
              <span>如出现意外事件、不可抗力等情形导致您的个人信息出现泄漏时，我们将极力控制局面，及时告知您事件起因、我们采取的安全措施、您可以主动采取的安全措施等相关信息。如发生个人信息有关的安全事件，我们将根据相关法律法规的要求向有关主管机关报备，及时进行问题排查，并采取应急措施。</span>
            </p>
          </li>
          <li>
            <p><span>五、 我们如何存储您的个人信息</span></p>
          </li>
          <li>
            <p>
              <span>存储位置：</span>我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：<br />
              1、法律法规有明确规定；<br />
              2、获得您的单独同意。
            </p>
          </li>
          <li>
            <p>针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。</p>
          </li>
          <li>
            <p>
              <span>存储期限：</span>除法律法规另有规定外，我们将按如下期间存储您的信息：<br />
              •&nbsp; 我们会储存我们收集到的您的个人信息以满足我们的正当业务需求（例如向您提供服务或者来满足相应的法律、税务以及财务的要求）。<br />
              •&nbsp; 当我们没有这些需要使用到您的个人信息的正当业务需求时或者是中国法律法规规定的留存时间届满时，我们会删除您的个人信息或者对其进行匿名化处理，或者在匿名化或者删除皆不可能实现（例如您的个人信息已经在备份中存储）的时候，安全地储存您的这些个人信息并且将这些个人信息与其他的数据处理区隔开来。
            </p>
          </li>
          <li>
            <p><span>六、 您的个人信息主体权利</span></p>
          </li>
          <li>
            <p>
              您有如下个人信息主体权利：<br />
              •&nbsp; 如您希望<span>访问、更正、更新以及删除</span>您的个人信息，以及行使其他中国法下的法定个人信息主体权利（比如撤回允许我们收集和处理您个人信息的同意、更改您同意的范围、转移您的个人信息（如适用）），您可以通过本隐私政策第九条所提供的联系方式随时与我们取得联系。
              <br />
              •&nbsp; 您有权随时退订我们不时向您发送的营销信息。您可以通过点击每则营销信息中所列的“退订”链接进行退订。如您希望退订其他形式的营销信息（比如通过邮寄或者电话等方式进行的营销），您可以通过本隐私政策第九条所提供的联系方式随时与我们取得联系。
            </p>
          </li>
          <li>
            <p>
              我们将会按照适用的数据保护法律的要求以及时间回应所有我们从个人信息主体收到的行使个人信息主体权利的请求。为保障安全，我们可能需要您提供书面请求或以其他方式证明您的身份。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。
            </p>
          </li>
          <li>
            <p>
              <span
                >根据法律法规规定，我们在如下的情况下可能无法回应您的行权请求：<br />
                •&nbsp; 如果您的请求与我们履行法律法规规定的义务所相悖；<br />
                •&nbsp; 如果请求的信息与国家安全、国防安全直接相关；<br />
                •&nbsp; 如果请求的信息与公共安全、公共卫生、重大公共利益直接相关；<br />
                •&nbsp; 如果请求的信息与刑事侦查、起诉、审判和执行判决等直接相关；<br />
                •&nbsp; 如果我们有充分证据表明您存在主观恶意或滥用权利的；<br />
                •&nbsp; 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意；<br />
                •&nbsp; 如果回应您的行权请求将会导致您或者其他个人、组织的合法利益受到严重损害；<br />
                •&nbsp; 如果请求的信息涉及商业秘密。</span
              >
            </p>
          </li>
          <li>
            <p>
              <span>如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</span>
            </p>
          </li>
          <li>
            <p><span>七、 我们如何处理未成年人的个人信息</span></p>
          </li>
          <li>
            <p>
              我们的网站、产品和服务主要面向成年人。如果没有父母或监护人的同意，不满14周岁的未成年人不得使用我们的网站、产品或服务。<span>如您是未满14岁的未成年人，您需要在您的父母或者法定监护人的指导下阅读本隐私政策。</span>我们只会在法律法规允许、父母或监护人明确同意的情况下收集未成年人的个人信息。如果我们发现自己在未事先获得父母或监护人明确同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
            </p>
          </li>
          <li>
            <p>如果您是未成年人的父母或者法定监护人，当您对未成年人的个人信息有相关疑问时，可通过本隐私政策第九条所提供的联系方式与我们取得联系。</p>
          </li>
          <li>
            <p><span>八、 本隐私政策的更新</span></p>
          </li>
          <li>
            <p>
              本隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在专门页面上发布对隐私政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知。 本隐私政策所指的重大变更包括但不限于：<br />
              •&nbsp; 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />
              •&nbsp; 我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；<br />
              •&nbsp; 个人信息提供或公开披露的主要对象发生变化；<br />
              •&nbsp; 您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />
              •&nbsp; 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；<br />
              •&nbsp; 因个人信息保护影响评估表明存在高风险而需要更改个人信息处理行为。
            </p>
          </li>
          <li>
            <p>您可以通过本隐私政策首部的“最后更新日期”了解到本隐私政策的更新时间。<span>若您在本隐私政策变更和修订之后继续使用我们的网站、产品或服务，表示您同意接受更新后的隐私政策。</span></p>
          </li>
          <li>
            <p><span>九、 如何联系我们</span></p>
          </li>
          <li>
            <p>
              如您对我们如何使用您的个人信息或本隐私政策有任何的问题或者疑虑，特别是认为我们的个人信息处理行为损害了您的合法权益，您可通过如下联系方式联系我们。我们将尽快审核所涉问题，并按照法律法规的要求和期限及时予以回复。<br />
              •&nbsp; 公司：安谋科技（中国）有限公司<br />
              •&nbsp; 联系地址：上海市闵行区田林路1016号科技绿洲三期11号楼<br />
              •&nbsp; 联系方式：privacy@armchina.com
            </p>
          </li> -->
        </ul>
        <div class="content" v-html="content"></div>
      </main>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
      date: '',
      content: '',
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    document.title = '安谋科技隐私政策'
    this.getPolicy_infos()
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
  methods: {
    async getPolicy_infos() {
      const res = await this.axios.get('v1/api/policy_infos')
      console.log(res)
      if (res.code === '00') {
        this.date = res.data.lastTime
        this.content = res.data.content
      }
    },
  },
}
</script>

<style lang="less" scoped>
.privacy {
  .left_br {
    text-align: right;
  }
  .pc {
    background: #000;
    .content {
      font-size: 0.2rem;
      color: #fff;
      width: 92%;
      /deep/p {
        line-height: 0.38rem;
        margin-bottom: 0.5rem;
      }
      /deep/ol {
        margin-bottom: 0.5rem;
        list-style-type: decimal;
        padding-left: 0.2rem;
        li {
          line-height: 0.38rem;
        }
      }
      /deep/ul {
        margin-bottom: 0.5rem;
        list-style-type: disc;
        padding-left: 0.2rem;
        li {
          line-height: 0.38rem;
          p {
            span {
              font-weight: bold;
            }
          }
        }
      }
      /deep/table {
        border-collapse: collapse;
        th,
        td {
          border: 1px solid #9c9a9a;
          padding: 0.1rem;
        }
      }
    }
    main {
      padding-left: 1.35rem;
      padding-top: 3.23rem;
      position: relative;
      .logo {
        width: 0.38rem;
        position: absolute;
        right: 0.75rem;
        top: 3.14rem;
      }
      h4 {
        font-size: 0.4rem;
        color: #fff;
      }
      .heng {
        width: 1.16rem;
        height: 0.03rem;
        background: #00b3d6;
        margin-top: 0.23rem;
      }
      ul {
        margin-top: 0.55rem;
        padding-right: 1.46rem;
        // margin-bottom: 1.26rem;
        li {
          margin-bottom: 0.55rem;
          color: #fff;
          font-size: 0.2rem;
          line-height: 0.38rem;
          p {
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .web {
    background: #000;
    // color: #cccccc;
    .content {
      font-size: 12px;
      color: #cccccc;
      padding-right: 21px;
      /deep/p {
        margin-bottom: 15px;
      }
      /deep/ol {
        margin-bottom: 15px;
        list-style-type: decimal;
        padding-left: 15px;
        li {
          line-height: 20px;
        }
      }
      /deep/ul {
        list-style-type: disc;
        padding-left: 10px;
        margin-bottom: 15px;
        li {
          line-height: 20px;
          p {
            font-size: 12px;
            color: #cccccc;
            span {
              font-weight: bold;
            }
          }
        }
      }
      /deep/table {
        border-collapse: collapse;
        th,
        td {
          border: 1px solid #9c9a9a;
          padding: 0.1rem;
        }
      }
    }
    main {
      padding-top: 87px;
      padding-left: 25px;
      h4 {
        font-size: 28px;
        color: #fff;
        margin-top: 58px;
        line-height: 34px;
      }
      .heng {
        width: 112px;
        height: 2px;
        background: #00b3d6;
        margin-top: 14px;
      }
      .logo {
        width: 133px;
        float: right;
        margin-right: 24px;
      }
      ul {
        margin-top: 26px;
        padding-right: 21px;
        li {
          margin-block: 26px;
          p {
            font-size: 12px;
            color: #cccccc;
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
    .foot {
      position: relative;
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>
